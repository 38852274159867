//
// 1. Set direction to RTL
//

html@{rtl-prefix} {
  direction: rtl;
}

//
// Remove default margin.
//

@{rtl-prefix} body {
  direction: rtl;
}
