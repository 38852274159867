// --------------------------------------------------
// Right-to-Left (RTL) Theme for Bootstrap 3.x
// Morteza Ansarinia
// http://github.com/morteza/bootstrap-rtl
// --------------------------------------------------

// Core variables and mixins imported from the latest original bootstrap
@import "../bootstrap/less/variables.less";
@import "variables-rtl.less";
@import "mixins-rtl.less";

// Reset and dependencies
@import "normalize-rtl.less";
//@import "print.less";
//@import "glyphicons.less";

// Core CSS
@{rtl-prefix} {
  //@import "scaffolding.less";
  @import "type-rtl.less";
  //@import "code.less";
  @import "grid-rtl.less";
  @import "tables-rtl.less";
  @import "forms-rtl.less";
  //@import "buttons.less";

  // Components
  //@import "component-animations.less";
  @import "dropdowns-rtl.less";
  @import "button-groups-rtl.less";
  @import "input-groups-rtl.less";
  @import "navs-rtl.less";
  @import "navbar-rtl.less";
  //@import "breadcrumbs.less";
  @import "pagination-rtl.less";
  @import "pager-rtl.less";
  //@import "labels.less";
  @import "badges-rtl.less";
  //@import "jumbotron.less";
  //@import "thumbnails.less";
  @import "alerts-rtl.less";
  @import "progress-bars-rtl.less";
  @import "media-rtl.less";
  @import "list-group-rtl.less";
  @import "panels-rtl.less";
  @import "responsive-embed-rtl.less";
  //@import "wells.less";
  @import "close-rtl.less";

  // Components w/ JavaScript
  @import "modals-rtl.less";
  //@import "tooltip.less";
  @import "popovers-rtl.less";
  @import "carousel-rtl.less";

  // Utility classes
  @import "utilities-rtl.less";
  //@import "responsive-utilities.less";
}
